import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { clientService } from '../../../services';

const EmailVerification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [responseMessage, setResponseMessage] = useState({ showBtn: true, message: '' });

  const { search, pathname } = useLocation();

  const hasRun = useRef(false);  // Ref to ensure handleUpdate only runs once

  // Function to validate if the URL path and token query are correct
  const isValidPath = () => {
    const isEmailVerifiedPath = pathname.startsWith('/emailVerified/');
    const hasToken = search.includes('?token=');
    return isEmailVerifiedPath && hasToken;
  };

  useEffect(() => {
    if (isValidPath() && !hasRun.current) {
      hasRun.current = true;  // Mark as run
      handleUpdate();
    }
  }, [search]);

  console.log('token: ', search);

  const handleUpdate = async () => {
    setIsLoading(true);
    setError(null); // Reset error

    try {
      console.log('Updating with: ');
      let postClient;

      // Example of the clientService call to update client details
      postClient = await clientService.updateLeadInfo(search.split('?token=')[1]);
      console.log('Update request:', postClient);

      // If message indicates the link is expired, handle accordingly
      if (postClient?.status !== 200) {
        setIsLinkExpired(true);
        setResponseMessage({
          showBtn: false,
          message: postClient?.data?.message
        })
      } else if (postClient?.status === 201) {
        setIsLinkExpired(false);
        setResponseMessage({
          showBtn: false,
          message: postClient?.data?.message
        });
      } else {
        setIsLinkExpired(false);
        setResponseMessage({
          showBtn: true,
          message: postClient?.data?.message
        });
      }

    } catch (err) {
      console.error('Error during verification:', err);
      setError('There was an error verifying your email. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // If the URL path is not valid, show "Page Not Found"
  if (!isValidPath()) {
    return (
      <div
        style={{
          backgroundColor: '#2752a073',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#ffffff',
            padding: '40px',
            borderRadius: '8px',
            maxWidth: '600px',
            width: '100%',
            textAlign: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <h2 style={{ color: 'red' }}>Page Not Found</h2>
          <p>The page you are looking for does not exist or the link is invalid.</p>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: '#2752a073',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: '#ffffff',
          padding: '40px',
          borderRadius: '8px',
          maxWidth: '600px',
          width: '100%',
          textAlign: 'center',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Logo at the top */}
        <div style={{ marginBottom: '30px' }}>
          <img
            src="/companyLogo_2.svg" // Update with your logo path
            alt="Company Logo"
            width="100"
            style={{ maxWidth: '100px' }}
          />
        </div>

        {isLoading ? (
          <div style={{ fontSize: '16px', color: '#555' }}>
            <div className="loader"></div> {/* The loader */}
            <p style={{ marginTop: '10px' }}>Please wait while we complete the verification...</p>
          </div>
        ) : error ? (
          <p style={{ fontSize: '16px', color: 'red' }}>{error}</p>
        ) : isLinkExpired ? (
          <div>
            <p style={{ fontSize: '16px', color: 'red' }}>
              {responseMessage.message}
            </p>
          </div>
        ) : (
          <p style={{ fontSize: '16px', color: '#555' }}>
            {responseMessage.message}
          </p>
        )}

        {/* Conditionally render the Login Button or Expired message */}
        {!isLinkExpired && !isLoading && !error && responseMessage.showBtn && (
          <div style={{ marginTop: '30px' }}>
            <a
              href="https://login.godocm.com/login/" // Update with your portal URL
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: '#1a82e2',
                color: '#fff',
                padding: '12px 30px',
                textDecoration: 'none',
                borderRadius: '6px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              Login to Client Portal
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;