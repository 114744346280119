import React, { useState } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";

const FormTypes = ({ setFormType }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [languageName, setLanguageName] = useState('Individual');

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const languageSelectHandler = (lang) => {
        setLanguageName(lang);
        setFormType(lang);
    };

    return (
        <div className={`dropdown`}>
            <button onClick={toggleDropdown} className={`dropbtn text-center`}>
                {languageName}
                <IoMdArrowDropdown />
            </button>
            {dropdownVisible && (
                <div id="myDropdown1" className="dropdown-content">
                    <div className='d-flex align-items-center justify-content-center' onClick={() => { languageSelectHandler('Individual'); toggleDropdown() }}>
                        Individual
                    </div>
                    <div className='d-flex aling-items-center justify-content-center' onClick={() => { languageSelectHandler('Coprate'); toggleDropdown() }}>
                        Corporate
                    </div>
                </div>
            )}
        </div>
    )
}

export default FormTypes
