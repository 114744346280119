import React from 'react';
import { RxCross2 } from "react-icons/rx";

import './SuccessfullMessage.css'

const SuccessfullMessage = ({ loading, setLoading }) => {

    const onCloseHandler = () => {
        setLoading('new');
    };

    return (
        <div
            style={{ cursor: 'pointer' }}
            className={`successWrapper ${loading === 'approved' ? 'd-flex' : 'd-none'} align-items-center justify-content-center`}
            onClick={onCloseHandler}
        >
            <div className='container successCard text-center py-4'>
                <RxCross2
                    style={{ cursor: 'pointer' }}
                    className='fs-3 float-end cursor'
                    onClick={onCloseHandler}
                />
                <br />
                <h4 className='mt-2'>Registration Successful</h4>
                <p>Dear Customer,</p>
                <p>An email has been sent to you for verification.</p>
                <p>If you don't see the email, please check your spam or junk folder.</p>
            </div>
        </div>
    )
};

export default SuccessfullMessage;
