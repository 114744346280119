import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { FormGroup, Row, Col, Label, Input } from 'reactstrap';
import Progress from "../../components/Progress/sca";
import countriesData from "../../components/json/countriesStates.json";
import countries from "../../components/json/resitrictedCountries.json";
import jsonData from '../../components/json/accountsType.json';
import { toast } from "react-toastify";
import { activitiesServices, clientService } from "../../services";
import IntlTelInput from 'react-intl-tel-input';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { Trans, useTranslation } from 'react-i18next';
import CryptoJS from "crypto-js";
import TokenService from "../../services/token.service";
// import ReCAPTCHA from "react-google-recaptcha";

import { Button, CustomForm } from "../Form/style";
import 'react-intl-tel-input/dist/main.css';
import '.././Form/style.css';
import '../../index.css';
import DirectorDetails from "./DirectorDetails";
import BeneficialOwner from "./BeneficialOwner";
import AuthorizedSignatory from "./AuthorizedSignatory";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const CorporateForm = ({ entity, setLoadingMain }) => {
    const [refValue, setRefValue] = useState('');
    const [ptrValue, setPtrValue] = useState('');
    const [referrar, setReferrar] = useState('');
    const [agreementId, setAgreementId] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [emailError, setEmailError] = useState(0);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [countryList, setCountriesList] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [utmMediumValue, setUtmMediumValue] = useState('');
    const [checkEmail, setCheckEmail] = useState(false);
    const [checkBox, setCheckBox] = useState('');
    const [filteredAccountTypes, setFilteredAccountTypes] = useState('');
    const [imageList, setImageList] = useState([]);
    const [userLogin, setUserLogin] = useState('');
    const [ipInfo, setIpInfo] = useState(null);
    const [entityValue, setEntityValue] = useState('');
    const [utmArray, setUtmArray] = useState({});
    const [morocco, setMorocco] = useState(false);
    const [platForm, setPlatForm] = useState('MT4');

    const navigate = useNavigate();
    const location = useLocation();
    const initialLoad = useRef(true);

    const [directors, setDirectors] = useState([
        { fullName: '', phoneNumber: '', address: '', tin: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '', customerId: '' }
    ]);

    const [beneficialOwners, setBeneficialOwners] = useState([
        { title: '', fullName: '', jobTitle: '', dob: '', tin: '', holdings: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '', customerId: '' }
    ]);

    const [authorizedSignatory, setAuthorizedSignatory] = useState([
        { fullName: '', jobTitle: '', email: '', proofOfIdentity: '', proofOfIdentityBack: '', resProofOfAddress: '', customerId: '' }
    ]);

    const { t, i18n } = useTranslation();


    const [formData, setFormData] = useState({
        step1: { agentId: '33', parentId: '', agreementId: agreementId, referrar: referrar, entity: 'FSC', callStatus: 'New Lead', firstName: '', email: '', phone: '', accountType: '', accountTypeCurrency: '', country: '', countryCode: '', dob: '', corporateLicense: '', tradingName: '', natureOfBusiness: '', address: '', address2: '', website: '', residency: '', taxIdentificationNumber: '', regulator: false, regulatorName: '', regulatorNumber: '', leiNo: '', giin: '', ...utmArray },
        step5: { liquidFinancial: '', companyDeclared: false, declaredNote: '', companyRecord: false, recordNote: '', annualTurover: '', accumulatedWealth: '', thirdParty: false, thirdPartyNote: '', fundsAvailable: '', initialDeposit: '', netAssets: '', accountName: '', bankName: '', sortCode: '', bankAccountNo: '', swift: '', iban: '' }
    });

    const [validationErrors, setValidationErrors] = useState({
        step1: {
            firstName: false, email: false, phone: false, accountType: false, password: false, confirmPassword: false, country: false, countryCode: false, platform: false, utmMedium: false, date: false, corporateLicense: false, tradingName: false, natureOfBusiness: false, address: false, address2: false, website: false, residency: false, taxIdentificationNumber: false, regulator: false, regulatorName: false, regulatorNumber: false, leiNo: false, giin: false
        },
        step2: {
            fullName: false, phone: false, address: false, tinDirector: false, proofOfIdentity: false, proofOfIdentityBack: false, resProofOfAddress: false,
        },
        step3: {
            title: false, fullName: false, jobTitle: false, dob: false, holdings: false, proofOfIdentity: false
        },
        step4: {
            fullName: false, jobTitle: false, email: false, proofOfIdentity: false
        },
        step5: {
            liquidFinancial: false, companyDeclared: false, declaredNote: false, companyRecord: false, recordNote: false,
            annualTurover: false, accumulatedWealth: false, thirdParty: false, thirdPartyNote: false, fundsAvailable: false,
            initialDeposit: false, netAssets: false, accountName: false, bankName: false, sortCode: false, bankAccountNo: false, swift: false
        },
    });

    useEffect(() => {
        // Ensure 'ptr' is present on initial load
        if (initialLoad.current) {
            initialLoad.current = false;
            const searchParams = new URLSearchParams(location.search);

            // Check for required search parameters
            if (!searchParams.has('ptr') || !searchParams.has('rm') || !searchParams.has('nationality')) {
                const newUrl = `${location.pathname}?${searchParams.toString()}`;
                navigate(newUrl, { replace: true });
            } else if (searchParams.has('nationality') && searchParams.get('nationality') === 'MOROCCO') {
                // Redirect directly to the Moroccan URL without localhost prefix
                const newUrl = `https://signup-ma.godocm.com/?${searchParams.toString()}`;
                window.location.href = newUrl;
            }
        }
    }, [location, navigate]);

    const reloadHandler = (country) => {
        // Get current search params
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('nationality', country);

        // Construct the new URL
        let newUrl;
        if (country === 'MOROCCO') {
            newUrl = `https://signup-ma.godocm.com/?${searchParams.toString()}`;
            window.location.href = newUrl;
        } else {
            newUrl = `${location.pathname}?${searchParams.toString()}`;
            // Push the new URL to the history
            navigate(newUrl);
        }
    };

    useEffect(() => {
        const fetchIPInfo = async () => {
            try {
                const response = await fetch('https://ipinfo.io/json?token=52a7645817b83a');
                const data = await response.json();
                setIpInfo(data);

                if (data) {
                    const country = countries.some(country => country.iso2 === data.country);
                    if (country) {
                        setEntityValue('FSA');
                    }
                }

            } catch (error) {
                console.error('Error fetching IP information:', error);
            }
        };

        fetchIPInfo();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        let ibUrl = window.location.origin + `/?ptr=${ptrParam}&rm=${refParam}`;

        if (ibUrl === `${window.location.origin}/?ptr=2000095497&rm=69`) {
            window.location.href = `https://signup.godocm.com/?entity=FSA_MA&utm_medium=godoweb_ma&ptr=2000095497`;
        }

        if ((ibUrl === `${window.location.origin}/?ptr=2000095497&rm=101`) || (refParam === '101') || (ipInfo !== null && ipInfo.country === 'MA')) {
            window.location.href = ptrParam ? `https://signup-ma.godocm.com/?ptr=${ptrParam}&rm=101&utm_medium=godoweb_ma` :
                `https://signup-ma.godocm.com/?rm=101&utm_medium=godoweb_ma`;
        }

    }, [ipInfo]);

    useEffect(() => {

        const searchParams = new URLSearchParams(window.location.search);
        const refParam = searchParams.get('rm');
        const ptrParam = searchParams.get('ptr');
        const refAddParam = searchParams.get('ref');
        const agreementParam = searchParams.get('agr');
        const entityParam = searchParams.get('entity');
        const platform = searchParams.get('platform');
        const partner = searchParams.get('partner');
        const nationality = searchParams.get('nationality');
        const utmSource = searchParams.get('utm_source');
        const utmCategory = searchParams.get('utm_category');
        const utm = searchParams.get('utm_medium');
        const utmCampaign = searchParams.get('utm_campaign');

        setUtmArray({
            utmCampaign: utmCampaign || '',
            utmCategory: utmCategory || '',
            utmSource: utmSource || '',
            utmMedium: utmMediumValue || utm || '',
        });

        if (entity === 'FSC') {
            const removeCountry = ['AFGHANISTAN', 'CUBA', 'GHANA', 'IRAN', 'LIBYA', 'MYANMAR', 'PANAMA', 'SOUNTH SUDAN', 'SYRIA', 'USA', 'YEMEN'];
            const filterCountry = countriesData.filter((country) => !removeCountry.includes(country.name));
            setCountriesList(filterCountry);
        } else if (entity === 'SCA') {
            const filterCountry = countriesData.filter((country) => country.name === "UNITED ARAB EMIRATES");
            setCountriesList(filterCountry);
        } else {
            setCountriesList(countriesData);
        }

        if (nationality === 'IRAN') {
            formData.step1.nationality = 'IRAN';
            formData.step1.nationalityCode = 'IR';
            formValueChange('step2', 'nationality', 'IRAN');
            formValueChange('step2', 'nationalityCode', 'IR');
        }

        if (nationality) {
            const formattedCountry = nationality.charAt(0) + nationality.slice(1).toLowerCase();
            const country = countries.find(country => country.name === formattedCountry);
            if (country) {
                formData.step1.country = nationality;
                formData.step1.countryCode = country.iso2;
                formValueChange('step1', 'country', nationality);
                formValueChange('step1', 'countryCode', country.iso2);
            }
        }
        // Parse the query parameters from the URL
        // Get the value of the 'ref' parameter
        if (partner === 'traderscaptain') {
            setRefValue('95');
            setPtrValue('2000111910');
        }

        // mobile app url
        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA' && (ipInfo !== null && ipInfo.country === 'MA')) {
            window.location.href = `https://signup-ma.godocm.com/?entity=FSA_MA&utm_medium=godotrader_app&platform=MT5`;
        }

        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA_MA') {
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.utmMedium = utm;
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        if (platform === 'MT5' && utm === 'godotrader_app' && entityParam === 'FSA') {
            setUtmMediumValue(utm);
            formData.step1.utmMedium = utm;
        }

        //desktop url
        if ((utm === 'godoweb_ma' && !platform)) {
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.utmMedium = utm;
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        if (platform === 'MT5' && utm === 'godoweb_ma' && entityParam === 'FSA_MA') {
            setUtmMediumValue(utm);
            setMorocco(true);
            formData.step1.utmMedium = utm;
            const filterCountry = countriesData.filter((country) => country.name === "MOROCCO");
            setCountriesList(filterCountry);
        }

        // Set the value to the state
        if (refParam) {
            setRefValue(refParam);
        }

        if (refAddParam) {
            setReferrar(refAddParam);
        }
        if (agreementParam) {
            setAgreementId(agreementParam);
        }

        if (ptrParam && entityParam !== 'FSA_MA' && !utm) {
            //setEntityValue(true) // seting entity to FSA
            setPtrValue(ptrParam);
        }

        clientService.authorization().then((res) => {
            const token = res.token;
            const decryptToken = decryptData(token);
            TokenService.setUser(decryptToken);
        }).catch((err) => {
            console.log('Error for auth is ', err);
            toast.error("Authorization error!");
        });
    }, [entity, morocco, utmMediumValue, ipInfo, formData.step1]);

    const data = {
        //step1
        agentId: refValue || '33',
        parentId: ptrValue,
        entity: entity || 'FSC',
        firstName: formData.step1.firstName,
        email: formData.step1.email,
        platform: formData.step1.platform,
        accountType: formData.step1.accountType,
        accountTypeCurrency: formData.step1.accountTypeCurrency,
        country: formData.step1.country,
        countryCode: formData.step1.countryCode,
        phone: formData.step1.phone,
        dob: formData.step1.date,
        address: formData.step1.address,
        address2: formData.step1.tradingAddress,
        website: formData.step1.website,
        residency: formData.step1.residency,
        taxIdentificationNumber: formData.step1.taxIdentificationNumber,
        ...utmArray,
        agreementId: agreementId,
    }

    const updateListFunction = (key, files) => {

        // Check if the key already exists in the imageList
        const existingIndex = imageList.findIndex(entry => entry.key === key);

        if (existingIndex !== -1) {
            // If the key already exists, update its value
            const updatedList = [...imageList];
            updatedList[existingIndex].value = files;
            setImageList(updatedList);

        } else {
            // If the key does not exist, add it as a new entry
            const newValue = {
                key,
                value: files
            };

            const updatedList = [...imageList, newValue];
            setImageList(updatedList);
        }
    };

    const filterAccountTypes = (platform) => {
        if (ptrValue && refValue !== 38) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['ib'] === 1))
        } else if (refValue === 38) {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 2))
        } else {
            return jsonData.filter(item => (item['TP platform'] === platform && item['Server Number'] === 1))
        }
    };

    const accountTypeValue = (platform) => {
        // Determine accountType based on platform
        const accountType = platform === 'MT5' ? '2' : '14';

        // Update form data
        setFormData((prevFormData) => ({
            ...prevFormData,
            step1: {
                ...prevFormData.step1,
                accountType,
                accountTypeCurrency: 'USD'
            },
        }));

    }


    const isStep1Valid = () => {
        const { firstName, email, phone, country, dob, corporateLicense, natureOfBusiness, address, website, residency, taxIdentificationNumber, regulator, regulatorName, regulatorNumber, leiNo, giin } = formData.step1;

        // Check each field individually and set their validity
        const isfirstNameValid = firstName.trim() !== '';
        const isEmailValid = email.trim() !== '';
        const isphoneValid = phone.trim() !== '';
        const isCountryValid = country !== '';
        const isDate = dob.trim() !== '';
        const isCorporateLicense = corporateLicense.trim() !== '';
        const isNatureOfBusiness = natureOfBusiness.trim() !== '';
        const isAddress = address.trim() !== '';
        const isCompanyWebsite = website.trim() !== '';
        const isTaxResidency = residency.trim() !== '';
        const isTin = taxIdentificationNumber.trim() !== '';
        const isRegulator = regulator;
        const isRegulatorName = regulatorName.trim() !== '';
        const isRegulatorNumber = regulatorNumber.trim() !== '';
        const isLeiNo = leiNo.trim() !== '';
        const isGiin = giin.trim() !== ''

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step1: {
                ...prevErrors.step1,
                firstName: !isfirstNameValid,
                email: !isEmailValid,
                phone: !isphoneValid,
                country: !isCountryValid,
                date: !isDate,
                corporateLicense: !isCorporateLicense,
                natureOfBusiness: !isNatureOfBusiness,
                address: !isAddress,
                website: !isCompanyWebsite,
                residency: !isTaxResidency,
                regulatorName: isRegulator && !isRegulatorName,
                regulatorNumber: isRegulator && !isRegulatorNumber,
                taxIdentificationNumber: !isTin,
                leiNo: !isLeiNo,
                giin: !isGiin
            }
        }));


        // Return true if all fields are valid, false otherwise
        const validTrue = isRegulator ? (isfirstNameValid && isEmailValid && isphoneValid &&
            isCountryValid && isDate && isCorporateLicense &&
            isNatureOfBusiness && isRegulatorName && isRegulatorNumber) :
            (isfirstNameValid && isEmailValid && isphoneValid &&
                isCountryValid && isDate && isCorporateLicense &&
                isNatureOfBusiness);

        if (!validTrue) {
            toast.error("Please fill all the fields!");
            return false;
        }

        return true;
    };

    const isStep2Valid = () => {
        let isValid = true; // Initialize to true by default
        console.log('director : ', directors);
        directors.forEach((director, index) => {
            const {
                fullName,
                phoneNumber,
                address,
                proofOfIdentity,
                proofOfIdentityBack,
                resProofOfAddress,
            } = director;

            // Set the validation errors dynamically for each field
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                step2: {
                    ...prevErrors.step2,
                    fullName: !fullName,
                    phone: !phoneNumber,
                    address: !address,
                    proofOfIdentity: !proofOfIdentity
                }
            }));

            // Check required fields
            if (!fullName || !phoneNumber || !address) {
                isValid = false;
                toast.error(`Please fill all required fields for director.`);
            }

            // Check if any uploaded files are missing
            const uploadedFiles = [proofOfIdentity, proofOfIdentityBack, resProofOfAddress];
            if (!proofOfIdentity) {
                isValid = false;
                toast.error(`Please upload all required files.`);
            }

            // Check if uploaded files are the same
            for (let i = 0; i < uploadedFiles.length; i++) {
                for (let j = i + 1; j < uploadedFiles.length; j++) {
                    if (uploadedFiles[i] && uploadedFiles[i] === uploadedFiles[j]) {
                        isValid = false;
                        toast.error(`Uploaded files cannot be the same for director.`);
                    }
                }
            }
        });

        return isValid; // Return the updated value of isValid
    };

    const isStep3Valid = () => {
        let isValid = true;

        beneficialOwners.forEach((beneficialOwner, index) => {
            const {
                title,
                fullName,
                jobTitle,
                dob,
                tin,
                holdings,
                proofOfIdentity,
                proofOfIdentityBack,
                resProofOfAddress,
            } = beneficialOwner;

            // Set the validation errors dynamically for each field
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                step3: {
                    ...prevErrors.step3,
                    title: !title,
                    fullName: !fullName,
                    jobTitle: !jobTitle,
                    dob: !dob,
                    holdings: !holdings,
                    proofOfIdentity: !proofOfIdentity
                }
            }));

            // Check required fields
            if (!title || !fullName || !jobTitle || !dob || !holdings) {
                isValid = false;
                toast.error(`Please fill all required fields for beneficial.`);
            }

            // Check if any uploaded files are missing
            const uploadedFiles = [proofOfIdentity, proofOfIdentityBack, resProofOfAddress];
            if (proofOfIdentity === '') {
                isValid = false;
                toast.error(`Please upload all required files.`);
            }

            // Check if uploaded files are the same
            for (let i = 0; i < uploadedFiles.length; i++) {
                for (let j = i + 1; j < uploadedFiles.length; j++) {
                    if (uploadedFiles[i] && uploadedFiles[i] === uploadedFiles[j]) {
                        isValid = false;
                        toast.error(`Uploaded files cannot be the same for director.`);
                    }
                }
            }
        });

        return isValid;
    };

    const isStep4Valid = () => {
        let isValid = true;

        authorizedSignatory.forEach((authorizedSignatory, index) => {
            const {
                fullName,
                jobTitle,
                email,
                proofOfIdentity,
                proofOfIdentityBack,
                resProofOfAddress,
            } = authorizedSignatory;

            // Set the validation errors dynamically for each field
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                step4: {
                    ...prevErrors.step4,
                    fullName: !fullName,
                    jobTitle: !jobTitle,
                    email: !email,
                    proofOfIdentity: !proofOfIdentity
                }
            }));

            // Check required fields
            if (!fullName || !jobTitle || !email) {
                isValid = false;
                toast.error(`Please fill all required fields for director.`);
            }

            // Check if any uploaded files are missing
            const uploadedFiles = [proofOfIdentity, proofOfIdentityBack, resProofOfAddress];
            if (proofOfIdentity === '') {
                isValid = false;
                toast.error(`Please upload all required files.`);
            }

            // Check if uploaded files are the same
            for (let i = 0; i < uploadedFiles.length; i++) {
                for (let j = i + 1; j < uploadedFiles.length; j++) {
                    if (uploadedFiles[i] && uploadedFiles[i] === uploadedFiles[j]) {
                        isValid = false;
                        toast.error(`Uploaded files cannot be the same for director.`);
                    }
                }
            }
        });

        return isValid;
    };

    const isStep5Valid = () => {
        console.log('stage 5')
            ; const { liquidFinancial, companyDeclared, declaredNote, companyRecord, recordNote, annualTurover, accumulatedWealth, thirdParty, fundsAvailable, initialDeposit, netAssets, accountName, bankName, sortCode, bankAccountNo, swift, iban } = formData.step5;

        // Check each field individually and set their validity
        const isliquidFinancialValid = liquidFinancial.trim() !== '';
        const iscompanyDeclaredValid = companyDeclared;
        const isdeclaredNoteeValid = declaredNote.trim() !== '';
        const iscompanyRecordValid = companyRecord !== '';
        const isrecordNoteValid = recordNote.trim() !== '';
        const isannualTuroverValid = annualTurover.trim() !== '';
        const isaccumulatedWealthValid = accumulatedWealth.trim() !== '';
        const isthirdPartyValid = thirdParty;
        const isfundAvailableValid = fundsAvailable.trim() !== '';
        const isinitialDepositValid = initialDeposit.trim() !== '';
        const isnetAssetsValid = netAssets.trim() !== '';
        const isaccountNameValid = accountName.trim() !== '';
        const isbankNameValid = bankName.trim() !== '';
        const issortCodeValid = sortCode.trim() !== '';
        const isbankAccountNoValid = bankAccountNo.trim() !== '';
        const isswiftValid = swift.trim() !== '';
        const isibanValid = iban.trim() !== '';

        // Set the validation errors dynamically for each field
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            step5: {
                ...prevErrors.step5,
                liquidFinancial: !isliquidFinancialValid,
                companyDeclaredValid: !iscompanyDeclaredValid,
                declaredNote: !isdeclaredNoteeValid,
                companyRecord: !iscompanyRecordValid,
                recordNote: !isrecordNoteValid,
                annualTurover: !isannualTuroverValid,
                accumulatedWealth: !isaccumulatedWealthValid,
                thirdParty: !isthirdPartyValid,
                fundsAvailable: !isfundAvailableValid,
                initialDeposit: !isinitialDepositValid,
                netAssets: !isnetAssetsValid,
                accountName: !isaccountNameValid,
                bankName: !isbankNameValid,
                sortCode: !issortCodeValid,
                bankAccountNo: !isbankAccountNoValid,
                swift: !isswiftValid,
                iban: !isibanValid,
            }
        }));


        // Return true if all fields are valid, false otherwise
        const validTrue = isliquidFinancialValid && isnetAssetsValid && isinitialDepositValid && isannualTuroverValid && isaccountNameValid && isbankNameValid &&
            issortCodeValid && isbankAccountNoValid && isswiftValid && isibanValid;

        if (!validTrue) {
            toast.error("Please fill all the fields!");
            return false;
        }

        return true;
    };

    const formValueChange = (step, name, value) => {
        const englishRegex = /^[a-zA-Z0-9\s.,'?!@#$%^&:*()_+=-]*$/;
        const uploadedFileNames = [
            'proofOfIdentity',
            'proofOfIdentityBack',
            'resProofOfAddress',
            'sourceOfIncome',
            'additional',
            'powerOfAttorney'
        ];

        const updateFormData = (newValue) => {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [step]: {
                    ...prevFormData[step],
                    [name]: newValue,
                },
            }));
        };

        const validateEmail = (email) => {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setEmailError(emailPattern.test(email) ? 0 : 1);
        };

        const handlePhoneNumber = (phone) => {
            const sanitizedPhone = phone.replace(/[^\d]/g, '');
            updateFormData(sanitizedPhone);
        };

        const handleFormValueChange = () => {
            if (name === 'email') {
                validateEmail(value);
                updateFormData(value);
            } else if (name === 'phone') {
                handlePhoneNumber(value);
            } else if (name === 'dob' || englishRegex.test(value) || uploadedFileNames.includes(name)) {
                updateFormData(value);
            }
        };

        handleFormValueChange();
    };

    const scrollToTopButton = () => {
        window.scrollTo({ top: 100, behavior: 'smooth' });
    }

    const submitForm = async (event, action) => {
        event.preventDefault();

        if (!checkBox) {
            setCheckBox(false);
        }

        if (action === 'next') {
            if (isStep1Valid() && checkBox === true && currentStep === 1) {
                console.log('data: ', formData.step1);
                const step1Data = { ...formData.step1, entity: entityValue !== '' ? entityValue : entity, agentId: refValue || '33', parentId: ptrValue, referrar: referrar, agreementId: agreementId, }

                // adding dial code into phone no
                step1Data.phone = phoneNumber !== undefined ? phoneNumber + formData.step1.phone.slice(1) : formData.step1.phone.slice(1);

                if (checkEmail) {
                    setCurrentStep(currentStep + 1);
                } else {
                    // console.log("Step 1 data is ", step1Data);
                    clientService.postCorprateLeadInfo(step1Data)
                        .then(async (res) => {

                            if (res && res.data && res.data.message) {
                                toast.error(res.data.message); // Display the error message
                            } else {
                                toast.success('User Created Successfully!');
                                setCurrentStep(currentStep + 1);
                                setCheckEmail(true);
                                setUserLogin(res);
                                setRefValue(res.agentId);
                                setPtrValue(res?.parentId);

                                // const logData = {
                                //     type: 'userRegister',
                                //     customerId: res.id,
                                //     note: { 'note': ipInfo && JSON.stringify(ipInfo) + ` urlLink=${window.location.href}` }
                                // }

                                // await activitiesServices.postActivity(logData);
                            }

                        })
                        .catch(() => {
                            toast.error('An error occurred while processing your request'); // Default error message
                        });
                    scrollToTopButton();
                }
            }

            if (currentStep === 2) {

                if (isStep2Valid()) {
                    console.log('director: ', directors);
                    setCurrentStep(currentStep + 1);
                }
            }

            if (currentStep === 3) {
                if (isStep3Valid()) {
                    console.log('beneficialOwner: ', beneficialOwners);
                    setCurrentStep(currentStep + 1);
                }
            }
            if (currentStep === 4) {
                if (isStep4Valid()) {
                    console.log('authorizedSignatory: ', authorizedSignatory);
                    setCurrentStep(currentStep + 1);
                }
            }
        } else if (action === 'previous') {
            setCurrentStep(currentStep - 1);
            scrollToTopButton();
        } else {

            if (isStep5Valid()) {
                setLoadingMain('pending');
                console.log('postCorporateInfoUpdate: ', authorizedSignatory);

                try {
                    // Prepare form data for document upload
                    const formDataValue = new FormData();
                    formDataValue.append('customerId', userLogin.id);
                    imageList.forEach((item) => {
                        formDataValue.append(item.key, item.value[0]); // Assuming each item contains only one file
                    });

                    // Upload documents
                    await clientService.uploadDocuments(formDataValue);

                    // Process directors data
                    const directorPromises = directors.map(async (value) => {
                        const directorData = { ...value };
                        delete directorData.proofOfIdentity;
                        delete directorData.proofOfIdentityBack;
                        delete directorData.resProofOfAddress;

                        await clientService.postDirectorInfo(directorData);
                    });

                    // Process beneficial owners data
                    const beneficialOwnerPromises = beneficialOwners.map(async (value) => {
                        const beneficialOwnersData = { ...value };
                        delete beneficialOwnersData.proofOfIdentity;
                        delete beneficialOwnersData.proofOfIdentityBack;
                        delete beneficialOwnersData.resProofOfAddress;

                        await clientService.postBeneficialOwnerInfo(beneficialOwnersData);
                    });

                    // Process authorized signatory data
                    const authorizedSignatoryPromises = authorizedSignatory.map(async (value) => {
                        const authorizedSignatoryData = { ...value };
                        delete authorizedSignatoryData.proofOfIdentity;
                        delete authorizedSignatoryData.proofOfIdentityBack;
                        delete authorizedSignatoryData.resProofOfAddress;

                        await clientService.postauthorizedSignatoryInfo(authorizedSignatoryData);
                    });

                    // Wait for all the above promises to resolve
                    await Promise.all([
                        ...directorPromises,
                        ...beneficialOwnerPromises,
                        ...authorizedSignatoryPromises
                    ]);

                    // Update corporate information
                    const response = await clientService.postCorporateInfoUpdate(userLogin.id, {
                        customer: data,
                        corporateId: userLogin.corporateId,
                        corporate: { 
                            corporateLicense: formData.step1.corporateLicense,
                            tradingName: formData.step1.tradingName,
                            natureOfBusiness: formData.step1.natureOfBusiness,
                            regulator: formData.step1.regulator,
                            regulatorName: formData.step1.regulatorName,
                            regulatorNumber: formData.step1.regulatorNumber,
                            leiNo: formData.step1.leiNo,
                            giin: formData.step1.giin,
                            ...formData.step5
                        }
                        });

                    if (response && response.data && response.data.message) {
                        // Show error message from the response
                        toast.error(response.data.message);
                        setLoadingMain('new');
                    } else {
                        // Successfully registered
                        toast.success('Successfully Registration!');
                        setLoadingMain('approved');
                    }

                } catch (error) {
                    // Catch any errors in the entire process
                    console.error(error);
                    toast.error('An error occurred while processing your request');
                    setLoadingMain('new');
                }

            }

        }
    };

    return (
        <div style={{ overflow: "hidden" }}>
            {/* <div id='recaptcha-container' style={{ display: 'block' }}></div> */}
            <>
                <div className="flex-center py-10 RSPBprogressBars d-sms-none">
                    <Progress currentStep={currentStep} />
                </div>
                <CustomForm style={{ fontSize: `${(i18n.language === 'de' || i18n.language === 'fa') ? '18px' : ''}` }}>
                    {currentStep === 1 &&
                        <div className="justify">
                            <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Full Corporate Name (As in Certificate of incorporation, Formation or Organization)</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + ((!validationErrors.step1.firstName || formData.step1.firstName) ? 'is-valid' : 'is-invalid')}
                                                name="firstName"
                                                type="text"
                                                required=""
                                                placeholder="John"
                                                value={formData.step1.firstName}
                                                onChange={(e) => {
                                                    // Remove non-alphanumeric characters except spaces
                                                    let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                    // Split the input into words, capitalize the first letter of each word, and join them back
                                                    let capitalizedInput = cleanedInput.split(' ')
                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                        .join(' ');

                                                    // Update the form value
                                                    formValueChange('step1', 'firstName', capitalizedInput);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`} >
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Establishment date</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + ((!validationErrors.step1.date || formData.step1.date) ? 'is-valid' : 'is-invalid')}
                                                name="date"
                                                type="date"
                                                required=""
                                                placeholder="dd-mm-yyyy"
                                                value={formData.step1.dob}
                                                onChange={(e) => {
                                                    // Update the form value
                                                    formValueChange('step1', 'dob', e.target.value);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Corporate License No</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + ((!validationErrors.step1.corporateLicense || formData.step1.corporateLicense) ? 'is-valid' : 'is-invalid')}
                                                name="corporateLicense"
                                                type="text"
                                                required=""
                                                placeholder="Corporate License No"
                                                value={formData.step1.corporateLicense}
                                                onChange={(e) => {
                                                    // Update the form value
                                                    formValueChange('step1', 'corporateLicense', e.target.value);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Trading Name (If Any) </Label>
                                        <div className="input-group">
                                            <Input className={"form-control "}
                                                name="tradingName"
                                                type="text"
                                                required=""
                                                placeholder="Trading Name"
                                                value={formData.step1.tradingName}
                                                onChange={(e) => {
                                                    // Remove non-alphanumeric characters except spaces
                                                    let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                    // Split the input into words, capitalize the first letter of each word, and join them back
                                                    let capitalizedInput = cleanedInput.split(' ')
                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                        .join(' ');

                                                    // Update the form value
                                                    formValueChange('step1', e.target.name, capitalizedInput);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Nature of Business</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + ((!validationErrors.step1.natureOfBusiness || formData.step1.natureOfBusiness) ? 'is-valid' : 'is-invalid')}
                                                name="natureOfBusiness"
                                                type="text"
                                                required=""
                                                placeholder="Nature of Business"
                                                value={formData.step1.natureOfBusiness}
                                                onChange={(e) => {
                                                    // Remove non-alphanumeric characters except spaces
                                                    let cleanedInput = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

                                                    // Split the input into words, capitalize the first letter of each word, and join them back
                                                    let capitalizedInput = cleanedInput.split(' ')
                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                        .join(' ');

                                                    // Update the form value
                                                    formValueChange('step1', e.target.name, capitalizedInput);
                                                }}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Country of incorporation</Label>
                                        <Input
                                            className={"mb-3 " + (!validationErrors.step1.country || formData.step1.country ? 'is-valid' : 'is-invalid')}
                                            type="select"
                                            name="country"
                                            onChange={(e) => {
                                                const selectedCountryName = e.target.value;
                                                const selectedCountry = countriesData.find(country => country.name === selectedCountryName);
                                                if (selectedCountry) {

                                                    if (selectedCountry.name === 'MOROCCO') {
                                                        reloadHandler('MOROCCO');
                                                    }

                                                    setSelectedCountryCode(selectedCountry.iso2.toLowerCase());
                                                    formValueChange('step1', 'country', selectedCountry.name);
                                                    formValueChange('step1', 'countryCode', selectedCountry.iso2);
                                                }
                                            }}
                                            value={formData.step1.country}
                                        >
                                            <option value="">-- Select --</option>
                                            {countryList.map((item, index) => (
                                                <option key={index} value={item.name}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Telephone No</Label>
                                        <IntlTelInput
                                            name="phone"
                                            containerClassName="intl-tel-input"
                                            inputClassName={"form-control " + ((!validationErrors.step1.phone || formData.step1.phone) ? 'is-valid' : 'is-invalid')}
                                            style={{ width: '100%' }}
                                            maxLength={15}
                                            format="true"
                                            autoFormat={false}
                                            separateDialCode={false}
                                            disabled={checkEmail ? true : false}
                                            defaultCountry={selectedCountryCode || 'ae'}
                                            value={formData.step1.phone}
                                            onPhoneNumberChange={(status, value, countryData, number, isValidNumberPrecise, id, fullNumber) => {
                                                if (value.length < 15) {
                                                    setPhoneNumber(countryData.dialCode);
                                                    formValueChange('step1', 'phone', value);
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Business Email Address</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + (!validationErrors.step1.email || formData.step1.email ? 'is-valid' : 'is-invalid')}
                                                type="email"
                                                name="email"
                                                disabled={checkEmail ? true : false}
                                                validation={{ required: true }}
                                                value={formData.step1.email}
                                                invalid={(emailError === 1 && formData.step1.email !== '' && 1) || validationErrors.step1.email}
                                                valid={(emailError === 0 && formData.step1.email !== '' && 1) || !validationErrors.step1.email}
                                                placeholder="john@gmail.com"
                                                onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Company Website</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + (!validationErrors.step1.website || formData.step1.website ? 'is-valid' : 'is-invalid')}
                                                type="text"
                                                name="website"
                                                validation={{ required: true }}
                                                value={formData.step1.website}
                                                placeholder="www.website.com"
                                                onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Registered Office Address (P.O Box No)</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + (!validationErrors.step1.address || formData.step1.address ? 'is-valid' : 'is-invalid')}
                                                type="text"
                                                name="address"
                                                validation={{ required: true }}
                                                value={formData.step1.address}
                                                placeholder="House No - Street - City - State"
                                                onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Business Trading Address (If different from above)</Label>
                                        <div className="input-group">
                                            <Input className={"form-control "}
                                                type="email"
                                                name="address2"
                                                validation={{ required: true }}
                                                value={formData.step1.address2}
                                                placeholder="House No - Street - City - State"
                                                onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Company tax residency</Label>
                                        <div className="input-group">
                                            <Input className={"form-control " + (!validationErrors.step1.residency || formData.step1.residency ? 'is-valid' : 'is-invalid')}
                                                type="email"
                                                name="residency"
                                                validation={{ required: true }}
                                                value={formData.step1.residency}
                                                placeholder="House No - Street - City - State"
                                                onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>TIN (if available)</Label>
                                        <div className="input-group">
                                            <Input className={"form-control "}
                                                type="text"
                                                name="taxIdentificationNumber"
                                                validation={{ required: true }}
                                                value={formData.step1.taxIdentificationNumber}
                                                placeholder="1234"
                                                onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`} >
                                <Col md={12}>
                                    <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                        <Col md={12}>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>LEI No</Label>
                                                        <div className="input-group">
                                                            <Input className={"form-control " + (!validationErrors.step1.leiNo || formData.step1.leiNo ? 'is-valid' : 'is-invalid')}
                                                                type="text"
                                                                name="leiNo"
                                                                validation={{ required: true }}
                                                                value={formData.step1.leiNo}
                                                                placeholder="leiNo"
                                                                onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>GIIN (if applicable)</Label>
                                                        <div className="input-group">
                                                            <Input className={"form-control "}
                                                                type="text"
                                                                name="giin"
                                                                validation={{ required: true }}
                                                                value={formData.step1.giin}
                                                                placeholder="giin"
                                                                onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} style={{ display: "none" }}>
                                                    <FormGroup>
                                                        <Label>{t('description.part14')}</Label>
                                                        <div className="input-group">
                                                            <Input
                                                                type="select"
                                                                name="platform"
                                                                className={formData.step1.platform ? "is-valid" : "is-invalid"}
                                                                value={formData.step1.platform}
                                                                onChange={(e) => {
                                                                    formValueChange('step1', e.target.name, e.target.value);
                                                                    setFilteredAccountTypes(filterAccountTypes(e.target.value));
                                                                    accountTypeValue(e.target.value);
                                                                }}
                                                            >
                                                                {platForm === 'MT4' && <option value="MT4">MT4</option>}
                                                                <option value="MT5">MT5</option>
                                                                <option value="CT">CTrader</option>
                                                            </Input>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} style={{ display: "none" }}>
                                                    <FormGroup>
                                                        <Label>{t('description.part15')}</Label>
                                                        <div className="input-group">
                                                            <Input
                                                                type="select"
                                                                name="accountType"
                                                                className={!validationErrors.step1.accountType || formData.step1.accountType ? 'is-valid' : 'is-invalid'}
                                                                value={formData.step1.accountType}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== '') {
                                                                        formValueChange('step1', e.target.name, e.target.value);
                                                                        const accountCurrency = filteredAccountTypes.filter((account) => account.Id === parseInt(e.target.value));
                                                                        formValueChange('step1', 'accountTypeCurrency', accountCurrency[0].Currency);
                                                                    }
                                                                }}
                                                            >
                                                                <option value="">-- Select --</option>
                                                                {filteredAccountTypes && filteredAccountTypes.map((item, index) => (
                                                                    <option key={index} value={item['Id']}>
                                                                        {item['Account type']}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} style={{ display: "none" }} className="position-relative">
                                                    <FormGroup>
                                                        <Label>{t('description.part16')}</Label>
                                                        <Input
                                                            type={`${showPassword ? "text" : "password"}`}
                                                            name="password"
                                                            className={"form-control required input_password " + (validationErrors.step1.password ? 'is-invalid' : 'is-valid')}
                                                            placeholder="***********"
                                                            minLength={8}
                                                            maxLength={12}
                                                            value={formData.step1.password}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target;
                                                                if (value.length < 13) {
                                                                    formValueChange('step1', name, value);
                                                                }
                                                            }}
                                                        />
                                                        {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6} style={{ display: "none" }} className="position-relative">
                                                    <FormGroup>
                                                        <Label>{t('description.part17')}</Label>
                                                        <Input
                                                            type={`${showPassword ? "text" : "password"}`}
                                                            name="confirmPassword"
                                                            className={"form-control required input_password " + (validationErrors.step1.confirmPassword ? 'is-invalid' : 'is-valid')}
                                                            placeholder="***********"
                                                            minLength={8}
                                                            maxLength={12}
                                                            value={formData.step1.confirmPassword}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target;
                                                                if (value.length < 13) {
                                                                    formValueChange('step1', name, value);
                                                                }
                                                            }}
                                                        />
                                                        {showPassword ? <FaRegEye onClick={() => setShowPassword(false)} className="position-absolute" style={{ top: "43px", right: "49px" }} /> : <FaRegEyeSlash onClick={() => setShowPassword(true)} className="position-absolute" style={{ top: "43px", right: "49px" }} />}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Label>Is the company regulated by any Financial Services Regulator?</Label>
                                    <div className={`input-group ms-2 mb-1 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                        <div className="form-check form-check-inline">
                                            <Input className="form-check-input"
                                                type="radio"
                                                name="regulator"
                                                id="regulatorYes"
                                                value="Yes"
                                                checked={formData.step1.regulator === true}
                                                onChange={(e) => formValueChange('step1', e.target.name, true)} />
                                            <Label className="form-check-label" htmlFor="regulatorYes">Yes</Label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Input className="form-check-input"
                                                type="radio"
                                                name="regulator"
                                                id="regulatorNo"
                                                value="No"
                                                checked={formData.step1.regulator === false}
                                                onChange={(e) => formValueChange('step1', e.target.name, false)} />
                                            <Label className="form-check-label" htmlFor="regulatorNo">  No</Label>
                                        </div>
                                    </div>
                                </Col>
                                {formData.step1.regulator &&
                                    <>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Name of Regulator</Label>
                                                <div className="input-group">
                                                    <Input className={"form-control " + (!validationErrors.step1.regulatorName || formData.step1.regulatorName ? 'is-valid' : 'is-invalid')}
                                                        type="text"
                                                        name="regulatorName"
                                                        validation={{ required: true }}
                                                        value={formData.step1.regulatorName}
                                                        placeholder="john"
                                                        onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Regulatory Number</Label>
                                                <div className="input-group">
                                                    <Input className={"form-control " + (!validationErrors.step1.regulatorNumber || formData.step1.regulatorNumber ? 'is-valid' : 'is-invalid')}
                                                        type="text"
                                                        name="regulatorNumber"
                                                        validation={{ required: true }}
                                                        value={formData.step1.regulatorNumber}
                                                        placeholder="123456789"
                                                        onChange={(e) => formValueChange('step1', e.target.name, e.target.value)}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </>}
                                <Col md={12}>
                                    <div className={`input-group mb-3 mt-4  d-flex align-items-center ${checkBox === '' || checkBox === true ? 'text-primary' : 'text-danger'} ${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                        <label style={{ direction: (i18n.language === 'de' || i18n.language === 'fa') && 'rtl' }} className={`d-flex align-items-start ${(i18n.language === 'de' || i18n.language === 'fa') ? ' text-end' : ''}`}>
                                            <input
                                                type="checkbox"
                                                name="correctInfo"
                                                className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'ms-3' : 'px-3 mt-1'}`}
                                                checked={Boolean(checkBox)}
                                                onChange={() => {
                                                    setCheckBox(!checkBox)
                                                }}
                                            />
                                            <span className="mx-2">
                                                The provided information is correct, and I authorize GoDo to use the information to assess the level of appropriateness of the provided services and products.
                                            </span>
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                    {currentStep === 2 &&
                        <DirectorDetails directors={directors} setDirectors={setDirectors} validationErrors={validationErrors} userLogin={userLogin} updateListFunction={updateListFunction} imageList={imageList} setImageList={setImageList} />
                    }
                    {currentStep === 3 && (
                        <BeneficialOwner beneficialOwner={beneficialOwners} setBeneficialOwner={setBeneficialOwners} validationErrors={validationErrors} userLogin={userLogin} updateListFunction={updateListFunction} imageList={imageList} setImageList={setImageList} />
                    )}
                    {currentStep === 4 &&
                        (<AuthorizedSignatory authorizedSignatory={authorizedSignatory} setAuthorizedSignatory={setAuthorizedSignatory} validationErrors={validationErrors} userLogin={userLogin} updateListFunction={updateListFunction} imageList={imageList} setImageList={setImageList} />)
                    }
                    {currentStep === 5 &&
                        <>
                            <div className="w-100">
                                <Row>
                                    <Col md={12} className="d-flex align-items-center gap-2"><h5>Financial Details</h5></Col>
                                </Row>
                            </div>
                            <div className="justify w_100" style={{ marginBlock: "10px" }}>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>What is the approximate size of the Company's liquid financial instrument profolio? <br /> (including cash deposits, investments but excluding any property)</Label>
                                            <Input
                                                className={"mb-3 " + ((!validationErrors.step5.liquidFinancial) ? 'is-valid' : 'is-invalid')}
                                                type="text"
                                                name="liquidFinancial"
                                                value={formData.step5.liquidFinancial}
                                                onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <Label>Has the Company ever been declared or subject to bankruptcy or insolvency proceedings?</Label>
                                        <div className={`input-group ms-2 mb-1 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="companyDeclared"
                                                    id="companyDeclared"
                                                    value="Yes"
                                                    checked={formData.step5.companyDeclared === true}
                                                    onChange={(e) => formValueChange('step5', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="companyDeclared"
                                                    id="companyDeclared"
                                                    value="No"
                                                    checked={formData.step5.companyDeclared === false}
                                                    onChange={(e) => formValueChange('step5', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingNo">No</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step5.companyDeclared && <Col md={12}>
                                        <FormGroup>
                                            <Label>If so, please provide details</Label>
                                            <Input
                                                className={"mb-3 " + ((!validationErrors.step5.declaredNote) ? 'is-valid' : 'is-invalid')}
                                                type="text"
                                                name="declaredNote"
                                                value={formData.step5.declaredNote}
                                                onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>}
                                    <Col md={12}>
                                        <Label>Has the Company or its directors ever been subject to any criminal, regulatory or legal proceedings?</Label>
                                        <div className={`input-group ms-2 mb-1 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="companyRecord"
                                                    id="companyRecord"
                                                    value="Yes"
                                                    checked={formData.step5.companyRecord === true}
                                                    onChange={(e) => formValueChange('step5', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="companyRecord"
                                                    id="companyRecord"
                                                    value="No"
                                                    checked={formData.step5.companyRecord === false}
                                                    onChange={(e) => formValueChange('step5', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingNo">No</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step5.companyRecord && <Col md={12}>
                                        <FormGroup>
                                            <Label>If so, please provide details</Label>
                                            <Input
                                                className={"mb-3 " + ((!validationErrors.step5.recordNote) ? 'is-valid' : 'is-invalid')}
                                                type="text"
                                                name="recordNote"
                                                value={formData.step5.recordNote}
                                                onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>}
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <Col md={12}>
                                        <Label>Annual Turnover USD</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + ((!validationErrors.step5.annualTurover) ? 'is-valid' : 'is-invalid')}
                                                name="annualTurover" type="text"
                                                value={formData.step5.annualTurover}
                                                required=""
                                                onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                                placeholder="USD" />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <Label>Description Of Source Of Funds <br></br>
                                            (Please details the origin of the funds to be placed on deposit with Godo. Source of funds refers to how these funds specifically were generated.)</Label>
                                        <div className="input-group mb-3">
                                            <Input
                                                className={"form-control " + ((!validationErrors.step5.accumulatedWealth) ? 'is-valid' : 'is-invalid')}
                                                name="accumulatedWealth" type="text"
                                                value={formData.step5.accumulatedWealth}
                                                required=""
                                                onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                                placeholder="Trading profit, personal savings, share sales and dividends" />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <Label>Do you operate as broker or intermediary for any third party?</Label>
                                        <div className={`input-group ms-2 mb-1 ${(i18n.language === 'de' || i18n.language === 'fa') ? 'align-items-end justify-content-end' : ''} `}>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="thirdParty"
                                                    id="thirdParty"
                                                    value="Yes"
                                                    checked={formData.step5.thirdParty === true}
                                                    onChange={(e) => formValueChange('step5', e.target.name, true)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingYes">Yes</Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input className="form-check-input"
                                                    type="radio"
                                                    name="thirdParty"
                                                    id="thirdParty"
                                                    value="No"
                                                    checked={formData.step5.thirdParty === false}
                                                    onChange={(e) => formValueChange('step5', e.target.name, false)} />
                                                <Label className="form-check-label" htmlFor="experiencedTradingNo">No</Label>
                                            </div>
                                        </div>
                                    </Col>
                                    {formData.step5.thirdParty && <Col md={12}>
                                        <FormGroup>
                                            <Label>If so, please provide details</Label>
                                            <Input
                                                className={"mb-3 " + ((!validationErrors.step5.thirdPartyNote) ? 'is-valid' : 'is-invalid')}
                                                type="text"
                                                name="thirdPartyNote"
                                                value={formData.step5.thirdPartyNote}
                                                onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>}
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Funds available for trading with GoDo (USD)</Label>
                                            <Input
                                                className={"mb-3 " + ((!validationErrors.step5.fundsAvailable) ? 'is-valid' : 'is-invalid')}
                                                type="text"
                                                name="fundsAvailable"
                                                value={formData.step5.fundsAvailable}
                                                onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Amount of intial deposit</Label>
                                            <Input
                                                className={"mb-3 " + ((!validationErrors.step5.initialDeposit) ? 'is-valid' : 'is-invalid')}
                                                type="text"
                                                name="initialDeposit"
                                                value={formData.step5.initialDeposit}
                                                onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Net Assets (USD)</Label>
                                            <div className="input-group">
                                                <Input
                                                    type="select"
                                                    name="netAssets"
                                                    className={!validationErrors.step5.netAssets ? "is-valid" : "is-invalid"}
                                                    value={formData.step5.netAssets}
                                                    onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                                >
                                                    <option value="">---Select---</option>
                                                    <option value="Under 500,000">Under 500,000</option>
                                                    <option value="500,001 - 2 mil">500,001 - 2 mil</option>
                                                    <option value="above 2 mil - 10 mil">above 2 mil - 10 mil</option>
                                                    <option value="above 10 mil- 20 mil">above 10 mil- 20 mil</option>
                                                    <option value="above 20 mil">above 20 mil</option>
                                                </Input>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                    <div className="w-100">
                                        <Row>
                                            <Col md={12} className="d-flex align-items-center gap-2"><h5>Banking Details (for all deposits and withdrawals)</h5></Col>
                                        </Row>
                                    </div>
                                    <div className="justify w_100" style={{ marginBlock: "10px" }}>
                                        <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>Account Name</Label>
                                                    <Input
                                                        className={"mb-3 " + ((!validationErrors.step5.accountName) ? 'is-valid' : 'is-invalid')}
                                                        type="text"
                                                        name="accountName"
                                                        value={formData.step5.accountName}
                                                        onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                                    >
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label>Bank Name and Branch</Label>
                                                    <Input
                                                        className={"mb-3 " + ((!validationErrors.step5.bankName) ? 'is-valid' : 'is-invalid')}
                                                        type="text"
                                                        name="bankName"
                                                        value={formData.step5.bankName}
                                                        onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                                    >
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                            <Col md={6}>
                                                <Label>Sort Code / BIC</Label>
                                                <div className="input-group mb-3">
                                                    <Input
                                                        className={"form-control " + ((!validationErrors.step5.sortCode) ? 'is-valid' : 'is-invalid')}
                                                        name="sortCode" type="text"
                                                        value={formData.step5.sortCode}
                                                        required=""
                                                        onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                                        placeholder="" />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <Label>Bank Account No</Label>
                                                <div className="input-group mb-3">
                                                    <Input
                                                        className={"form-control " + ((!validationErrors.step5.bankAccountNo) ? 'is-valid' : 'is-invalid')}
                                                        name="bankAccountNo" type="text"
                                                        value={formData.step5.bankAccountNo}
                                                        required=""
                                                        onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                                        placeholder="" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={`${(i18n.language === 'de' || i18n.language === 'fa') ? 'flex-row-reverse text-end' : ''}`}>
                                            <Col md={6}>
                                                <Label>SWIFT</Label>
                                                <div className="input-group mb-3">
                                                    <Input
                                                        className={"form-control " + ((!validationErrors.step5.swift) ? 'is-valid' : 'is-invalid')}
                                                        // className={"form-control " + (check && !formData.step2.idIssuedPlace && 'is-invalid')}
                                                        name="swift"
                                                        type="text"
                                                        value={formData.step5.swift}
                                                        required=""
                                                        placeholder=""
                                                        onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <Label>IBAN</Label>
                                                <div className="input-group mb-3">
                                                    <Input
                                                        className={"form-control " + ((!validationErrors.step5.iban) ? 'is-valid' : 'is-invalid')}
                                                        name="iban"
                                                        type="text"
                                                        value={formData.step5.iban}
                                                        required
                                                        onChange={(e) => formValueChange('step5', e.target.name, e.target.value)}
                                                        placeholder="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Row>
                            </div>
                        </>
                    }
                    <div className="justify align-items-center d-flex flex-column flex-sm-row gap-y-2 mt-3 ">
                        {currentStep === 1 ? '' : <Button onClick={(e) => submitForm(e, 'previous')} className='primary-bg-color col buttonWidth mobile-disabled' style={{ height: '50px' }}>
                            {t('Previous')}
                        </Button>}
                        <Button type="submit" onClick={currentStep === 5 ? (e) => submitForm(e, 'submit') : (e) => submitForm(e, 'next')} className='primary-bg-color col buttonWidth' style={{ height: '50px', background: '#26539F' }}>
                            {currentStep === 5 ? t('Submit') : t('Next')}
                        </Button>
                    </div>
                    <div className="mt-3">
                        <Row md={12}>
                            <Col>
                                <p className="text-danger">
                                    <Trans i18nKey="description.part29">
                                        <b>Exercise caution:</b> If uncertain about trading risks or uncomfortable with leveraging, it's advisable not to proceed with the application form, as trading entails the potential loss of your entire investment.
                                    </Trans>
                                </p>
                            </Col>
                        </Row>
                    </div>
                    {/*<div>
                            <ReCAPTCHA
                                size="invisible"
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                onChange={handleRecaptcha}
                                ref={recaptchaRef}
                            />
                        </div>*/}
                </CustomForm>
            </>
        </div>
    )
}